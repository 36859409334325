html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0E0E0E !important;
}

button {
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.arrow {
  position: absolute;
  transition: 0.2s;
  top: 45%;
  z-index: 2;
  cursor: pointer;
}

.arrow-left {
  left: 2%;
}

.arrow-right {
  right: 2%;
}

.arrow:hover {
  transform: scale(1.1);
}

.swiper-pagination-horizontal {
  bottom: 0 !important;
}

.swiper-pagination-bullet {
  width: 30px !important;
  height: 14px !important;
  border: 1px solid #636363;
  border-radius: 10px !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
}

@media(max-width: 1024px){
  .swiper-pagination-horizontal {
    bottom: -5px !important;
  }

  .swiper-pagination-bullet {
    width: 20px !important;
    height: 10px !important;
    border: 1px solid #828080;
    border-radius: 10px !important;
  }

  .arrow {
    top: 43%;
    width: 15px !important;
  }

  .arrow-left {
    left: 4%;
  }
  
  .arrow-right {
    right: 4%;
  }
}

.swiper {
  width: 600px;
  height: 300px;
}
