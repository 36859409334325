@keyframes fadeIn {  
  from {  
      opacity:0;  
  }  

  to {  
      opacity:1;  
  }  
}

.view {
  animation: fadeIn 0.5s ease-in; 
  z-index: 6;
}

.minHeight {
  min-height: 60vh;
}

@media(min-width: 900px){
}