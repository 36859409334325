.headerButtonBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.headerButton {
  width: 88%;
  margin-left: 10px;
  margin-top: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active {
  color: var(--primary-color);
}

.name {
  width: 90%;
  font-size: 18px;
}

.arrow {
  width: 18px;
  height: 18px;
  object-fit: contain;
}