.button {
  width: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
  background: var(--primary-color);
  border: 2px solid white;
  padding: 4px 15px;
  margin-right: 10px;
  transition: 0.3s;
  box-sizing: border-box;
  font-size: 18px;
}

.button:hover {
  background-color: rgba(206, 188, 81, 0.7) !important;
}

.primary {
  background-color: var(--primary-color) !important;
}

.secondary {
  background: rgba(0, 0, 0, 0.8) !important;
}

.icon {
  width: 23px !important;
  margin-right: 10px;
  max-width: initial;
}

.loadingIcon {
  width: 20px !important;
  margin-left: 10px;
}

@media(min-width: 900px){
  .button {
    padding: 6px 20px;
  }

  .loadingIcon {
    margin-bottom: 4px;
  }
}