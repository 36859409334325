@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;800&family=Roboto:wght@300;400;500;700&display=swap');

.loading {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0);
  z-index: 9999;
}

.loading img {
  width: 100px;
  animation: fadein 3s infinite linear;
}

.text {
  width: 90%;
  line-height: 1.5;
  text-align: center;
  animation: fadein 3s infinite linear;
  font-family: 'Jost';
  color: white;
  margin-top: 30px;
  font-size: 22px;
  font-weight: 500;
}

@media (min-width: 900px) {
  .loading img {
    width: 140px;
    animation: fadein 3s infinite linear;
  }

  .text {
    font-size: 30px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
