
.loading{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0);
  z-index: 9999;
}
 
.loading img{
  width: 100px;
  animation: fadein 1.8s infinite linear;
}


@keyframes fadein {
  0% { 
    opacity: 0; 
  }
  50% { 
    opacity: 1; 
  }
  100% { 
    opacity: 0; 
  }
}

