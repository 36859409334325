.footer {
  position: relative;
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: black;
  padding: 10px 0 40px 0;
}

.version {
  position: absolute;
  bottom: 10px;
  font-size: 13px;
  width: 100%;
  text-align: center;
  z-index: 999;
}

.bear {
  margin-top: 10px;
}

.networkField {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.joinButton {
  margin-top: 20px;
  text-decoration: underline;
  font-size: 20px;
}

.middle {
  width: 90%;
}

.newsletter {
  width: 90%;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}

.inputField {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.inputField img{
  width: 49%;
}

.input {
  width: 100%;
}

.subscribeButton {
  width: 100% !important;
  background: var(--primary-color) !important;
  margin: 0 !important; 
  height: 54px !important;
  margin-top: 10px !important;
  box-sizing: border-box !important;
} 

.download {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
  margin-top: 40px;
}

.links {
  margin-top: 45px;
  width: 90%;
  font-size: 18px;
  font-weight: 400;
}

.links li {
  transition: 0.2s;
  cursor: pointer;
}

.links li:hover {
  color: var(--primary-color);
}

.bottom {
  width: 90%;
  margin-top: 10px;
}

.subtitle {
  font-weight: 500;
  margin-top: 20px;
  font-size: 18px;
}

.socials {
  width: 90%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.social {
  margin-right: 10px;
  width: 45px;
}

@media(min-width: 900px) {
  .footer {
    padding: 35px 100px 50px 70px;
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  .bottom {
    width: auto;
  }

  .links {
    width: 15%;
  }

  .middle {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .download {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin-right: 0;
    margin-top: 40px;
  }
 
  .newsletter {
    width: 100%;
  }

  .inputField {
    flex-direction: row;
  }

  .inputField img {
    width: 49%;
  }

  .subscribeButton {
    width: 40% !important;
    margin-top: 0 !important;
    margin-left: 10px !important;
  }

  .joinButton {
    transition: 0.2s;
  }

  .joinButton:hover {
    cursor: pointer;
    color: white;
    text-decoration: underline;
  }

  .networkField {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

