.joinTheNetwork {
  font-weight: 600;
}

.active {
  color: var(--primary-color) !important; 
}

.headerButton {
  cursor: pointer;
}

.categoryField {
  display: none;
  padding: 20px 10px;
  justify-content: space-around;
  flex-direction: column;
  z-index: 500;
  position: absolute;
  top: 30px;
  background:  var(--dark-color);
  max-height: 450px;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 999;
}

.headerButton:hover .categoryField {
  display: flex;
}

.signUp {
  background: white;
  border: 1px solid var(--primary-color);
  color: #0E0E0E;
  font-weight: 600;
  padding: 8px 15px;
}

.categoryButton {
  display: flex;
  width: 150px;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.2s;
  color: white !important;
}

.categoryButton:hover {
  color: var(--primary-color) !important;
}

.mobileDropdown {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 90px;
  left: 0;
  background-color: #0E0E0E;
}

.mobileHeaderButton {
  width: 88%;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}