.page {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.left {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 5%;
}

.logoField {
  width: 25vw;
  height: 25vw;
  object-fit: contain;
  margin-bottom: 7%;
}

.left h4 {
  width: 100%;
  text-transform: none;
}

.priceField {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: lightgray;
  margin-bottom: 5%;
}

.priceField h1 {
  margin-right: 10px;
  text-transform: initial;
}

.priceLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right  {
  width: 100%;
}

.cardContainer {
  width: 90vw;
}

.button {
  width: 100%;
}

.backButton {
  position: absolute;
  top: 12%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  color: white;
}

.backButton img {
  width: 30px;
  margin-right: 5px;
}

@media (min-width: 767px) {
  .page {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px;
  }

  .backButton {
    position: initial;
    margin-bottom: 14vh;
  }

  .left {
    width: 30%;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    padding-top: 18vh;
  }

  .right {
    width: 30%;
    padding-top: 22vh;
  }

  .priceField {
    text-align: center;
  }

  .logoField {
    width: 100px;
    height: 100px;
  }

  .cardContainer {
    width: 100%;
    max-width: 380px;
  }
  .button{
    max-width: 380px;
  }
}
