.inputField {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.input {
  width: 100%;
  border: 1px solid white !important;
}

.input::placeholder {
  color: white;
  font-size: 14px;
}

.warnInput {
  border-color: red !important;
}

.warnText {
  font-size: 14px;
  margin-top: 5px;
  color: red;
}